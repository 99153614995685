import { useThree } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import { WebGLRenderer } from "three";
import { KTX2Loader } from "three-stdlib";

export function useCompressedGLTF(path: string) {
  let gl = useThree((state) => state.gl);
  return useGLTF(path, true, true, (loader) => {
    let ktx2Loader = new KTX2Loader();
    ktx2Loader.dispose();
    ktx2Loader.detectSupport(gl);
    ktx2Loader.setTranscoderPath(
      "https://cdn.jsdelivr.net/gh/pmndrs/drei-assets@master/basis/"
    );
    loader.setKTX2Loader(ktx2Loader);
  });
}

useCompressedGLTF.preload = function (path: string, gl: WebGLRenderer) {
  useGLTF.preload(path, true, true, (loader) => {
    let ktx2Loader = new KTX2Loader();
    ktx2Loader.dispose();
    ktx2Loader.detectSupport(gl);
    ktx2Loader.setTranscoderPath(
      "https://cdn.jsdelivr.net/gh/pmndrs/drei-assets@master/basis/"
    );
    loader.setKTX2Loader(ktx2Loader);
  });
};
