import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useCompressedGLTF } from "./hooks";
import { useEffect, useRef } from "react";

type GLTFResult = GLTF & {
  nodes: {
    [key: string]: THREE.Mesh;
  };
  materials: {
    [key: string]: THREE.MeshStandardMaterial | THREE.MeshPhysicalMaterial;
  };
};




//#region ModernTheme GLTF URL
const url2 = new URL("../static/ModernTheme/modern_plan.glb", import.meta.url);
const MODERN_PICTURES = new URL("../static/ModernTheme/modernFrame.glb", import.meta.url);
const MODERN_NANGAN = new URL("../static/ModernTheme/modernnangan.glb", import.meta.url);
const MODERN_TOPBACK_WALL = new URL("../static/ModernTheme/Modern_TopBack_Wall.glb", import.meta.url);
const MODERN_WALL_LIGHT = new URL("../static/ModernTheme/Modern_Wall_Light.glb", import.meta.url);
const MODERN_WALLS = new URL("../static/ModernTheme/Modern_Walls.glb", import.meta.url);
const MODERN_WINE = new URL("../static/ModernTheme/Modern_Wine.glb", import.meta.url);
const MODERN_TABLE = new URL("../static/ModernTheme/Modern_Table.glb", import.meta.url);
const MODERN_SOFA2= new URL("../static/ModernTheme/modern_Sofa_Compressed.glb", import.meta.url);
//#endregion

//#region  CompressedSofa
export function CompressedSofa() {
  const { nodes, materials } = useCompressedGLTF(MODERN_SOFA2.href) as GLTFResult;
  const modelRef = useRef() as any;
    useEffect(() => {
      // 모델이 로드된 후 위치 조정
      if (modelRef.current) {
        const box = new THREE.Box3().setFromObject(modelRef.current);
        const center = box.getCenter(new THREE.Vector3());
        modelRef.current.position.sub(center);

            modelRef.current.position.x -= 0.1;
            modelRef.current.position.y += 0.42;
            modelRef.current.position.z -= 0;
        
      }
    }, [nodes]);
  return (
    <group dispose={null}>
      <mesh
        ref={modelRef}
        castShadow
        receiveShadow
        geometry={nodes.mesh_0.geometry}
        material={materials.Blue_Sofa_V002}
        scale={0.00022}
      />
    </group>
  );
}
//#endregion



//#region ModernTable
function ModernTable() {
  const { nodes, materials } = useGLTF(MODERN_TABLE.href) as GLTFResult;
  return (
    <group dispose={null}>
      <group name="Scene">
        <mesh
          name="Clear_Black_Table_V002"
          castShadow
          receiveShadow
          geometry={nodes.Clear_Black_Table_V002.geometry}
          material={materials.Clear_Black_Table_V002}
        />
      </group>
    </group>
  );
  }
//#endregion

//#region ModernWine
function ModernWine() {
  const { nodes, materials } = useGLTF(MODERN_WINE.href) as GLTFResult;
  return (
    <group  dispose={null}>
      <group name="Scene">
        <mesh
          name="Wine_glass001"
          castShadow
          receiveShadow
          geometry={nodes.Wine_glass001.geometry}
          material={materials["Glass clean"]}
          position={[0.06, 0.31, -0.12]}
        />
        <group name="Bottle" position={[0, 0.31, 0]}>
          <mesh
            name="Vert"
            castShadow
            receiveShadow
            geometry={nodes.Vert.geometry}
            material={materials["Glass dark"]}
          />
          <mesh
            name="Vert_1"
            castShadow
            receiveShadow
            geometry={nodes.Vert_1.geometry}
            material={materials["Copper Insulation Foil"]}
          />
        </group>
      </group>
    </group>
  );
  }
//#endregion

//#region  ModernWalls
function ModernWalls() {
  const { nodes, materials } = useGLTF(MODERN_WALLS.href) as GLTFResult;
  return (
    <group  dispose={null}>
      <group name="Scene">
        <mesh
          name="Fframe"
          castShadow
          receiveShadow
          geometry={nodes.Fframe.geometry}
          material={materials["Material.003"]}
          position={[0, 0, 5]}
        />
        <mesh
          name="Fglass"
          castShadow
          receiveShadow
          geometry={nodes["Fglass"].geometry}
          material={materials.Material}
          position={[0, 0, 5]}
        />
        <mesh
          name="B"
          castShadow
          receiveShadow
          geometry={nodes.B.geometry}
          material={materials["Material.003"]}
          position={[0, 0, -5]}
        />
        <group name="R" position={[5, 0, 0]}>
          <mesh
            name="Plane006"
            castShadow
            receiveShadow
            geometry={nodes.Plane006.geometry}
            material={materials.Material}
          />
          <mesh
            name="Plane006_1"
            castShadow
            receiveShadow
            geometry={nodes.Plane006_1.geometry}
            material={materials["Material.003"]}
          />
        </group>
        <mesh
          name="L"
          castShadow
          receiveShadow
          geometry={nodes.L.geometry}
          material={materials["Material.003"]}
          position={[-5, 0, 0]}
        />
        <mesh
          name="fram_5"
          castShadow
          receiveShadow
          geometry={nodes.fram_5.geometry}
          material={materials["Material.010"]}
          position={[0, 0, -4.97]}
        />
        <mesh
          name="fram_2"
          castShadow
          receiveShadow
          geometry={nodes.fram_2.geometry}
          material={materials["Material.010"]}
          position={[-5, 0, -2.2]}
        />
        <mesh
          name="fram"
          castShadow
          receiveShadow
          geometry={nodes.fram.geometry}
          material={materials["Material.010"]}
          position={[-5, 0, 2.7]}
        />
        <mesh
          name="fram_4"
          castShadow
          receiveShadow
          geometry={nodes.fram_4.geometry}
          material={materials["Material.010"]}
          position={[5, 0, 2.2]}
        />
        <mesh
          name="fram_3"
          castShadow
          receiveShadow
          geometry={nodes.fram_3.geometry}
          material={materials["Material.010"]}
          position={[5, 0, -2.7]}
        />
        <mesh
          name="R001"
          castShadow
          receiveShadow
          geometry={nodes.R001.geometry}
          material={materials.Material}
          position={[5, 0, 0]}
        />
        <mesh
          name="B001"
          castShadow
          receiveShadow
          geometry={nodes.B001.geometry}
          material={materials.Material}
          position={[0, 0, -5]}
        />
        <mesh
          name="L001"
          castShadow
          receiveShadow
          geometry={nodes.L001.geometry}
          material={materials.Material}
          position={[-5, 0, 0]}
        />
        <mesh
          name="fram_4001"
          castShadow
          receiveShadow
          geometry={nodes.fram_4001.geometry}
          material={materials["Material.009"]}
          position={[5, 0, 2.2]}
        />
        <mesh
          name="fram_5001"
          castShadow
          receiveShadow
          geometry={nodes.fram_5001.geometry}
          material={materials["Material.009"]}
          position={[0, 0, -4.97]}
        />
        <mesh
          name="fram_2001"
          castShadow
          receiveShadow
          geometry={nodes.fram_2001.geometry}
          material={materials["Material.009"]}
          position={[-5, 0, -2.2]}
        />
        <mesh
          name="fram001"
          castShadow
          receiveShadow
          geometry={nodes.fram001.geometry}
          material={materials["Material.009"]}
          position={[-5, 0, 2.7]}
        />
        <mesh
          name="fram_3001"
          castShadow
          receiveShadow
          geometry={nodes.fram_3001.geometry}
          material={materials["Material.009"]}
          position={[5, 0, -2.7]}
        />
      </group>
    </group>
  );
}

//#endregion

//#region  ModernWallLight
function ModernWallLight() {
  const { nodes, materials } = useGLTF(MODERN_WALL_LIGHT.href) as GLTFResult;
  return (
    <group dispose={null}>
      <group name="Scene">
        <group name="Spot_wall_light" position={[-1.88, 6.75, -4.96]}>
          <mesh
            name="G-No_Name_006004"
            castShadow
            receiveShadow
            geometry={nodes["G-No_Name_006004"].geometry}
            material={materials["Material.007"]}
          />
          <mesh
            name="G-No_Name_006004_1"
            castShadow
            receiveShadow
            geometry={nodes["G-No_Name_006004_1"].geometry}
            material={materials["[Color_008]"]}
          />
          <mesh
            name="G-No_Name_006004_2"
            castShadow
            receiveShadow
            geometry={nodes["G-No_Name_006004_2"].geometry}
            material={materials.CARVALHO}
          />
          <mesh
            name="G-No_Name_006004_3"
            castShadow
            receiveShadow
            geometry={nodes["G-No_Name_006004_3"].geometry}
            material={materials.cromo}
          />
        </group>
        <group name="Spot_wall_light_1" position={[-4.95, 4.39, -0.92]}>
          <mesh
            name="G-No_Name_006017"
            castShadow
            receiveShadow
            geometry={nodes["G-No_Name_006017"].geometry}
            material={materials["Material.007"]}
          />
          <mesh
            name="G-No_Name_006017_1"
            castShadow
            receiveShadow
            geometry={nodes["G-No_Name_006017_1"].geometry}
            material={materials["[Color_008]"]}
          />
          <mesh
            name="G-No_Name_006017_2"
            castShadow
            receiveShadow
            geometry={nodes["G-No_Name_006017_2"].geometry}
            material={materials.CARVALHO}
          />
          <mesh
            name="G-No_Name_006017_3"
            castShadow
            receiveShadow
            geometry={nodes["G-No_Name_006017_3"].geometry}
            material={materials.cromo}
          />
        </group>
        <group name="Spot_wall_light_2" position={[-4.95, 4.39, 3.9]}>
          <mesh
            name="G-No_Name_006018"
            castShadow
            receiveShadow
            geometry={nodes["G-No_Name_006018"].geometry}
            material={materials["Material.007"]}
          />
          <mesh
            name="G-No_Name_006018_1"
            castShadow
            receiveShadow
            geometry={nodes["G-No_Name_006018_1"].geometry}
            material={materials["[Color_008]"]}
          />
          <mesh
            name="G-No_Name_006018_2"
            castShadow
            receiveShadow
            geometry={nodes["G-No_Name_006018_2"].geometry}
            material={materials.CARVALHO}
          />
          <mesh
            name="G-No_Name_006018_3"
            castShadow
            receiveShadow
            geometry={nodes["G-No_Name_006018_3"].geometry}
            material={materials.cromo}
          />
        </group>
        <group name="Spot_wall_light_3" position={[4.95, 4.39, 1.03]}>
          <mesh
            name="G-No_Name_006019"
            castShadow
            receiveShadow
            geometry={nodes["G-No_Name_006019"].geometry}
            material={materials["Material.007"]}
          />
          <mesh
            name="G-No_Name_006019_1"
            castShadow
            receiveShadow
            geometry={nodes["G-No_Name_006019_1"].geometry}
            material={materials["[Color_008]"]}
          />
          <mesh
            name="G-No_Name_006019_2"
            castShadow
            receiveShadow
            geometry={nodes["G-No_Name_006019_2"].geometry}
            material={materials.CARVALHO}
          />
          <mesh
            name="G-No_Name_006019_3"
            castShadow
            receiveShadow
            geometry={nodes["G-No_Name_006019_3"].geometry}
            material={materials.cromo}
          />
        </group>
        <group name="Spot_wall_light_4" position={[4.96, 4.39, -3.79]}>
          <mesh
            name="G-No_Name_006020"
            castShadow
            receiveShadow
            geometry={nodes["G-No_Name_006020"].geometry}
            material={materials["Material.007"]}
          />
          <mesh
            name="G-No_Name_006020_1"
            castShadow
            receiveShadow
            geometry={nodes["G-No_Name_006020_1"].geometry}
            material={materials["[Color_008]"]}
          />
          <mesh
            name="G-No_Name_006020_2"
            castShadow
            receiveShadow
            geometry={nodes["G-No_Name_006020_2"].geometry}
            material={materials.CARVALHO}
          />
          <mesh
            name="G-No_Name_006020_3"
            castShadow
            receiveShadow
            geometry={nodes["G-No_Name_006020_3"].geometry}
            material={materials.cromo}
          />
        </group>
      </group>
    </group>
  );
  }
//#endregion

//#region  ModernTopBackWall
function ModernTopBackWall() {
  const { nodes, materials } = useGLTF(MODERN_TOPBACK_WALL.href) as GLTFResult;
  return (
    <group  dispose={null}>
      <group name="Scene">
        <mesh
          name="T"
          castShadow
          receiveShadow
          geometry={nodes.T.geometry}
          material={materials["Material.002"]}
          position={[0, 10, 0]}
        />
        <mesh
          name="T001"
          castShadow
          receiveShadow
          geometry={nodes.T001.geometry}
          material={materials["Material.017"]}
          position={[0, 10, 0]}
        />
        <mesh
          name="B004"
          castShadow
          receiveShadow
          geometry={nodes.B004.geometry}
          material={materials["Material.004"]}
          position={[0, 0, 5]}
        />
      </group>
    </group>
  );
  }
//#endregion

//#region  ModernNanGan
function ModernNanGan() {
    const { nodes, materials } = useGLTF(MODERN_NANGAN.href) as GLTFResult;
  return (
    <group  dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube006.geometry}
        material={materials["Material.012"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube006_1.geometry}
        material={materials["Material.011"]}
      />
    </group>
  );
}
//#endregion

//#region  ModernPictures
function ModernPictures() {
  const { nodes, materials } = useGLTF(MODERN_PICTURES.href) as GLTFResult;
  return (
    <group  dispose={null}>
      <group name="Scene">
        <mesh
          name="pictureMain"
          castShadow
          receiveShadow
          geometry={nodes["pictureMain"].geometry}
          material={materials.Picframe}
        />
        <mesh
          name="pictureR"
          castShadow
          receiveShadow
          geometry={nodes.pictureR.geometry}
          material={materials["Picframe.001"]}
        />
        <mesh
          name="pictureR001"
          castShadow
          receiveShadow
          geometry={nodes.pictureR001.geometry}
          material={materials["Picframe.002"]}
        />
        <mesh
          name="pictureL001"
          castShadow
          receiveShadow
          geometry={nodes.pictureL001.geometry}
          material={materials["Picframe.004"]}
        />
        <mesh
          name="pictureL"
          castShadow
          receiveShadow
          geometry={nodes.pictureL.geometry}
          material={materials["Picframe.003"]}
        />
      </group>
    </group>
  );
}
//#endregion

//#region  ModernPlane
function ModernPlane() {
    const { nodes: modern_plane_nodes, materials: modern_plan_materials } =
      useGLTF(url2.href) as GLTFResult;
  
  return (
    <mesh
      castShadow
      receiveShadow
      geometry={modern_plane_nodes.PC001.geometry}
      material={modern_plan_materials["plan.004"]}
    />
  );
  }
//#endregion

export function Modern(props: JSX.IntrinsicElements["group"]) {
  return (
    <>
      <ModernWalls/>
      <ModernNanGan/>
      <ModernPictures />
      <ModernTopBackWall />
      <ModernWallLight />
      <ModernWine />
      {/* <ModernSofa /> */}
      <ModernTable />
      <ModernPlane />
      <CompressedSofa/>
    </>
  );
}
