export default class State {
    states: string[];
    currentIndex: number;
    xButton: boolean;
    yButton: boolean;
    constructor() {
        this.states = ['default', 'classic', 'modern'];
        this.currentIndex = 0;
        this.xButton = true;
        this.yButton = true;
    }

    next() {
        if (this.xButton === true) {
            this.currentIndex = (this.currentIndex + 1) % this.states.length;
            this.xButton = false;
            return this.states[this.currentIndex];
        }
        else {
            return this.states[this.currentIndex];
        }
    }

    prev() {
        if (this.yButton === true) {
            this.currentIndex = (this.currentIndex - 1 + this.states.length) % this.states.length;
            this.yButton = false;
            return this.states[this.currentIndex];
        }
        else {
             return this.states[this.currentIndex];
        }
    }
}