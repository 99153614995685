import React, {  Suspense, useRef } from "react";
import ReactDOM from "react-dom/client";
import { VRCanvas } from "@react-three/xr";
import { Modern } from "./modern";
import { Environment, OrbitControls, PositionalAudio, useHelper} from "@react-three/drei";
import { Default } from "./deafult";
import { Classic } from "./classic";
import { Physics } from "@react-three/cannon";
import Theme3Primitiveform from "./Theme3Primitive";
import Player from "./vrPlayer";
import { bgmModeAtom, themeAtom } from "./atoms";
import { useAtomValue } from "jotai";
import { DirectionalLight, DirectionalLightHelper } from "three";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const classic_hdr = new URL("../static/classic_hdr.hdr", import.meta.url);
const modern_hdr = new URL("../static/modern_hdr.hdr", import.meta.url);
const default_hdr = new URL("../static/default_hdr.hdr", import.meta.url);
const classic_bgm = new URL("../static/classic_bgm.mp3", import.meta.url);
const modern_bgm = new URL("../static/modern_bgm.mp3", import.meta.url);
const default_bgm = new URL("../static/default_bgm.mp3", import.meta.url);

type DirPosition = {
  x: number;
  y: number;
  z: number;
}
function Theme() {
  let theme = useAtomValue(themeAtom);
  let bgm = useAtomValue(bgmModeAtom);
  const dirLight = useRef<DirectionalLight>(null);
  useHelper(dirLight as any, DirectionalLightHelper, "red" as any);

  const dirPos = { x: 0, y: 0, z: 0 } as DirPosition;
  const ShadowMap = { left: 10, right: 10, top: 10, bottom: 10, near: 10, far: 10 };
  let bias = 0.01;
  let intensity = 0.5;
    if (theme === 'default') {
      dirPos.x = 13.0;
      dirPos.y = 26.0;
      dirPos.z = 4.9;
      ShadowMap.left = -10;
      ShadowMap.right = 10;
      ShadowMap.top = 10;
      ShadowMap.bottom = -10;
      ShadowMap.near = 0.01;
      ShadowMap.far = 100;
      bias = 0.0001;
      intensity = 0.5;
    }
    else if (theme === 'modern') { 
      dirPos.x = -0.35;
      dirPos.y = 0.4;
      dirPos.z = 0.17;
      ShadowMap.left = -10;
      ShadowMap.right = 10;
      ShadowMap.top = 10;
      ShadowMap.bottom = -10;
      ShadowMap.near = 0.01;
      ShadowMap.far = 100;
      bias = 0.01;
       intensity = 0.5;
    }
    else if (theme === 'classic') {
      dirPos.x = 24.0;
      dirPos.y = 23.0;
      dirPos.z = 14.4;
      ShadowMap.left = -50;
      ShadowMap.right = 50;
      ShadowMap.top = 50;
      ShadowMap.bottom = -50;
      ShadowMap.near = 0.01;
      ShadowMap.far = 500;
      bias = 0.01
      intensity = 1;
     }

  
  return (
    <>
      <ambientLight intensity={0.2} />
      <directionalLight
        castShadow
        intensity={intensity}
        shadow-mapSize={[1024, 1024]}
        shadow-bias={bias}
        position={[dirPos.x, dirPos.y, dirPos.z]}
      >
        <orthographicCamera
          attach="shadow-camera"
          args={[
            ShadowMap.left,
            ShadowMap.right,
            ShadowMap.top,
            ShadowMap.bottom,
            ShadowMap.near,
            ShadowMap.far,
          ]}
        />
      </directionalLight>

      {theme === "default" ? (
        <>
          <Environment files={default_hdr.href} background={true} />
          <Default />
          <Suspense fallback={null}>
            {bgm ? (
              <PositionalAudio
                autoplay
                loop
                url={default_bgm.href}
                load={undefined}
              />
            ) : null}
          </Suspense>
        </>
      ) : null}
      {theme === "classic" ? (
        <>
          <pointLight intensity={0.5} position={[0, 5, 0]} />
          <Environment files={classic_hdr.href} background={true} />
          <Classic />
          <Suspense fallback={null}>
            {bgm ? (
              <PositionalAudio
                autoplay
                loop
                url={classic_bgm.href}
                load={undefined}
              />
            ) : null}
          </Suspense>
        </>
      ) : null}
      {theme === "modern" ? (
        <>
          <Environment files={modern_hdr.href} background={true} />
          <pointLight intensity={0.7} position={[0, 5, 0]} />
          <Modern />
          <Suspense fallback={null}>
            {bgm ? (
              <PositionalAudio
                autoplay
                loop
                url={modern_bgm.href}
                load={undefined}
              />
            ) : null}
          </Suspense>
        </>
      ) : null}
    </>
  );
}

root.render(
  <React.StrictMode>
    <div style={{ width: "100vw", height: "100vh" }}>
      <VRCanvas shadows = {'soft'} >
        <OrbitControls />
        <Physics>
          <Theme3Primitiveform
            THREE_WIDTH={15}
            THREE_HEIGHT={0}
            THREE_DEPTH={15}
          />
          <Player />
        </Physics>
        <Theme/>
      </VRCanvas>
    </div>
  </React.StrictMode>
);

