import { useXR, useController } from "@react-three/xr";
import { useEffect, useMemo, useRef } from "react";
import { PublicApi, useSphere } from "@react-three/cannon";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

import { SetStateAction, useAtom, useSetAtom} from 'jotai'
import State from "./themeSelect";
import { themeAtom, bgmModeAtom } from "./atoms";
import bgmOnOff from "./bgmOnOff";

function MovementController({
  hand = "right",
  rotationSensitivity = 0.05,
  deadzone = 0.05,
  horizontalAxis = 2,
  forwardAxis = 3,
  rotationAxis = 2,
  applyRotation = true,
  ref,
  api,
  themeState,
  bgmOnOff,
}: {
  hand: XRHandedness;
  rotationSensitivity?: number;
  deadzone?: number;
  horizontalAxis?: number;
  forwardAxis?: number;
  rotationAxis?: number;
  applyRotation?: boolean;
  ref?: React.RefObject<THREE.Object3D<THREE.Event>>;
  api?: PublicApi;
  themeState?: State;
  bgmOnOff?: bgmOnOff;
}) {
  const { player } = useXR();
  const controller = useController(hand);
  let forward = useRef<number>(0);
  let horizontal = useRef<number>(0);
  const velocity = useRef([0, 0, 0]);
  const direction = useMemo(() => new THREE.Vector3(), []);
  const themeNumber = useSetAtom(themeAtom);
  const bgmMode = useSetAtom(bgmModeAtom);
  
  useEffect(() => {
    if (api) api.velocity.subscribe((v) => (velocity.current = v));
  }, []);

  useFrame(() => {
    if (controller && controller.inputSource.gamepad && ref && api) {
      let { axes } = controller.inputSource.gamepad;
      const buttons = controller.inputSource.gamepad?.buttons;
      if (hand === "left") {
        ref.current?.getWorldPosition(player.position);

        horizontal.current = axes[horizontalAxis] as number;
        forward.current = axes[forwardAxis];
        direction
          .set(horizontal.current, 0, forward.current)
          .multiplyScalar(5)
          .applyEuler(player.rotation);
        api.velocity.set(direction.x, velocity.current[1], direction.z);

        api.rotation.set(0, player.rotation.y, 0);
        if (themeState) {
          if (buttons[4].pressed === true) {
            themeNumber(themeState.next());
          }
          if (buttons[4].pressed === false) {
            themeState.xButton = true;
          }
          if (buttons[5].pressed === true) {
            themeNumber(themeState.prev());
          }
          if (buttons[5].pressed === false) {
            themeState.yButton = true;
          }
        }
      }

      if (hand === "right") {
        if (bgmOnOff) {
            if (buttons[4].pressed === true) {
              bgmMode(bgmOnOff?.OnOff());
            }
            if (buttons[4].pressed === false) {
              if (bgmOnOff !== undefined) bgmOnOff.xButton = true;
            }
          }
    
      }

      if (applyRotation) {
        player.rotation.y -=
          (Math.abs(axes[rotationAxis]) > deadzone ? axes[rotationAxis] : 0) *
          rotationSensitivity;
      }
    }
  });
}


export default function Player() {
  const [ref, api] = useSphere(() => ({
    mass: 50,
    position: [0, 0.1, 3],
    scale: [1, 1, 1],
    args: [1],
  }));

  const themeState = new State();
  const bgmOnOffState = new bgmOnOff();
  MovementController({
    hand: 'left',
    rotationSensitivity: 0.05,
    horizontalAxis: 2,
    forwardAxis: 3,
    rotationAxis: 2,
    deadzone: 0.05,
    applyRotation: false,
    ref: ref,
    api: api,
    themeState: themeState,
  });

    MovementController({
      hand: "right",
      rotationSensitivity: 0.05,
      horizontalAxis: 2,
      rotationAxis: 2,
      deadzone: 0.05,
      applyRotation: true,
      ref: ref,
      api: api,
      bgmOnOff: bgmOnOffState,
    });
  return (
      <mesh ref={ref as any}/>
  )
 
}