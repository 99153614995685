import { usePlane, useBox } from "@react-three/cannon";

function Plane(props: any) {
  let [ref] = usePlane(() => ({
    rotation: props.rotation,
    position: props.position,
  }));
  return <mesh ref={ref as any} />;
}

function Cube(props: any) {
  let [ref] = useBox(() => ({
    type: "Static",
    position: props.position,
    args: props.args,
    rotation: props.rotation,
  }));
  return <mesh ref={ref as any} />;
}

export default function Theme3Primitiveform({
  THREE_WIDTH,
  THREE_HEIGHT,
  THREE_DEPTH,
}: {
  THREE_WIDTH: number;
  THREE_HEIGHT: number;
  THREE_DEPTH: number;
}) {
  return (
    <>
      <Plane
        position={[0, -1, 0]}
        args={[THREE_WIDTH, THREE_DEPTH]}
        rotation={[-Math.PI / 2, 0, 0]}
      />
      <Cube
        position={[-5, THREE_HEIGHT / 2, 0]}
        args={[THREE_DEPTH, THREE_WIDTH, 0.1]}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <Cube
        position={[5, THREE_HEIGHT / 2, 0]}
        args={[THREE_DEPTH, THREE_WIDTH, 0.1]}
        rotation={[0, Math.PI / 2, 0]}
      />
      <Cube
        position={[0, THREE_HEIGHT / 2, 5]}
        args={[THREE_WIDTH, 10, 0.1]}
        rotation={[0, 0, 0]}
      />
      <Cube
        position={[0, THREE_HEIGHT / 2, -5]}
        args={[THREE_WIDTH, 10, 0.1]}
        rotation={[0, 0, 0]}
      />
    </>
  );
}
