import * as THREE from "three";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useEffect, useRef } from "react";
import { useCompressedGLTF } from "./hooks";



type GLTFResult1 = GLTF & {
  nodes: {
    [key: string]: THREE.Mesh;
  };
  materials: {
    [key: string]: THREE.MeshStandardMaterial | THREE.MeshPhysicalMaterial;
  };
};

//#region  CompressedSofa
export function CompressedSofa() {
  const { nodes, materials } = useCompressedGLTF(CLASSIC_SOFA.href) as GLTFResult1;
  const modelRef = useRef() as any;
    useEffect(() => {
      // 모델이 로드된 후 위치 조정
      if (modelRef.current) {
        const box = new THREE.Box3().setFromObject(modelRef.current);
        const center = box.getCenter(new THREE.Vector3());
        modelRef.current.position.sub(center);

            modelRef.current.position.x -= 0;
            modelRef.current.position.y += 0.49;
            modelRef.current.position.z -= 0.53;
        
      }
    }, [nodes]);
  return (
    <group dispose={null}>
      <mesh
        ref={modelRef}
        castShadow
        geometry={nodes.mesh_0.geometry}
        material={materials.Chair_005}
         scale={0.00015}
      />
    </group>
  );
}
//#endregion


//#region  ClassicModel URL
const CLASSIC_PICTURES = new URL("../static/ClassicTheme/classicFrame.glb", import.meta.url);
const CLASSIC_PLANE = new URL("../static/ClassicTheme/classicB.glb", import.meta.url);
const CLASSIC_SANSEVIERIA = new URL("../static/ClassicTheme/classic_Sansevieria.glb", import.meta.url);
const CLASSIC_CACTUS = new URL("../static/ClassicTheme/classic_Cactus.glb", import.meta.url);
const CLASSIC_TABLE = new URL("../static/ClassicTheme/classic_Table.glb", import.meta.url);
const CLASSIC_FRONT_PILLAR = new URL("../static/ClassicTheme/classic_Front_Pillar.glb", import.meta.url);
const CLASSIC_FRONT_WALL = new URL("../static/ClassicTheme/classic_Front_Wall.glb", import.meta.url);
const CLASSIC_LEFT_WALL = new URL("../static/ClassicTheme/classic_Left_Wall.glb", import.meta.url);
const CLASSIC_PLANE_SIDE = new URL("../static/ClassicTheme/classic_Plane_Side.glb", import.meta.url);
const CLASSIC_TOP_WALL = new URL("../static/ClassicTheme/classic_Top_Wall.glb", import.meta.url);
const CLASSIC_RIGHT_WALL = new URL("../static/ClassicTheme/classic_Right_Wall.glb", import.meta.url);
const CLASSIC_TOP_FRAME = new URL("../static/ClassicTheme/classic_Top_Frame.glb", import.meta.url);
const CLASSIC_WINDOW = new URL("../static/ClassicTheme/classic_Window.glb", import.meta.url);
const CLASSIC_SOFA = new URL(
  "../static/ClassicTheme/classic_Chair_Compressed.glb",
  import.meta.url
);
//#endregion

//#region  ClassicWindow
function ClassicWindow() {
  const { nodes, materials } = useGLTF(CLASSIC_WINDOW.href) as GLTFResult1;
  return (
    <group dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.window001.geometry}
        material={materials["Material.008"]}
        position={[5.14, 0.61, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.window002.geometry}
        material={materials["Material.008"]}
        position={[-5.14, 0.61, 0]}
      />
    </group>
  );
}
//#endregion

//#region ClassicTopFrame
function ClassicTopFrame() {
  const { nodes, materials } = useGLTF(CLASSIC_TOP_FRAME.href) as GLTFResult1;
  return (
    <group dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.TFrame.geometry}
        material={nodes.TFrame.material}
        position={[0.01, 11.34, 20]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Tmolding001.geometry}
        material={nodes.Tmolding001.material}
        position={[-5, 8.25, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Tmolding002.geometry}
        material={nodes.Tmolding002.material}
        position={[5, 8.25, 0]}
      />
    </group>
  );
  }
//#endregion

//#region  CLassicRightWall
function ClassicRightWall() {
  const { nodes, materials } = useGLTF(CLASSIC_RIGHT_WALL.href) as GLTFResult1;
  return (
    <group dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.R.geometry}
        material={materials["Material.034"]}
        position={[5, 0, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.LPillar001.geometry}
        material={nodes.LPillar001.material}
        position={[5.01, 0, 5]}
        rotation={[-Math.PI, 0, -Math.PI]}
      />
    </group>
  );
  }
//#endregion

//#region ClassicTopWall
function ClassicTopWall() {
  const { nodes, materials } = useGLTF(CLASSIC_TOP_WALL.href) as GLTFResult1;
  return (
    <group  dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane002.geometry}
        material={materials["Material.051"]}
        position={[0, 9.27, -5]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003.geometry}
        material={materials["Material.051"]}
        position={[-5, 9.27, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane001.geometry}
        material={materials["Material.051"]}
        position={[0, 9.27, 44.97]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane004.geometry}
        material={materials["Material.051"]}
        position={[5, 9.27, 0]}
      />
    </group>
  );
  }
//#endregion

//#region ClassicPlaneSide
function ClassicPlaneSide() {
  const { nodes} = useGLTF(CLASSIC_PLANE_SIDE.href) as GLTFResult1;
  return (
    <group  dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.LPillar006.geometry}
        material={nodes.LPillar006.material}
        position={[-5.52, 0, 5]}
      />
    </group>
  );
  }
  
//#endregion

//#region ClassicLeftWall
function ClassicLeftWall() { 
  const { nodes, materials } = useGLTF(CLASSIC_LEFT_WALL.href) as GLTFResult1;
  return (
    <group dispose={null}>
      <mesh
        receiveShadow
        geometry={nodes.L.geometry}
        material={materials["Material.034"]}
        position={[-5, 0, 0]}
        rotation={[0, 0, 0]}
      />
      <mesh
        receiveShadow
        geometry={nodes.LPillar.geometry}
        material={nodes.LPillar.material}
        position={[-5, 0, 5]}
      />
    </group>
  );
}

//#endregion

//#region Classic_Front_Wall
function ClassicFrontWall() {
  const { nodes, materials } = useGLTF(CLASSIC_FRONT_WALL.href) as GLTFResult1;
  return (
    <group dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.doorframe.geometry}
        material={nodes.doorframe.material}
        position={[0, 0, 45]}
      />
      <mesh
        receiveShadow
        geometry={nodes.B.geometry}
        material={materials["Material.034"]}
        rotation={[0, 0, 0]}
        position={[0, 0, -5]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.F001.geometry}
        material={materials["Material.002"]}
        position={[0, 0, 45.04]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.door_2.geometry}
        material={materials["Material.001"]}
        position={[-1.05, 0, 44.36]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.door_1.geometry}
        material={materials["Material.001"]}
        position={[1.05, 0, 44.36]}
      />
    </group>
  );
  }
//#endregion 

//#region  ClassicFrontPillar
function ClassicFrontPillar() {
  const { nodes } = useGLTF(CLASSIC_FRONT_PILLAR.href) as GLTFResult1;
  return (
    <group  dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["FPillar"].geometry}
        material={nodes["FPillar"].material}
        position={[0, 0, -5]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["FPillar001"].geometry}
        material={nodes["FPillar001"].material}
        position={[0, 0, 45]}
      />
    </group>
  );
  }
//#endregion

//#region  ClassicTable
function ClassicTable() {
  const { nodes, materials } = useGLTF(CLASSIC_TABLE.href) as GLTFResult1;
  return (
    <group  dispose={null}>
    <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder003.geometry}
        material={materials["Material.004"]}
        position={[0, 0, 0.14]}
        rotation={[0, 0.8, 0]}
     />
    </group>
  );
}
//#endregion


//#region ClassicCactus
function ClassicCactus() {
  const { nodes, materials } = useGLTF(CLASSIC_CACTUS.href) as GLTFResult1;
     return (
       <group  dispose={null}>
         <mesh
           castShadow
           receiveShadow
           geometry={nodes.Cactus.geometry}
           material={materials.clay}
           position={[-0.02, 0.54, 0.14]}
         >
           <group position={[0, 0.17, 0]} scale={0.05}>
             <mesh
               castShadow
               receiveShadow
               geometry={nodes.Sphere_1.geometry}
               material={materials.cactus}
             />
             <mesh
               castShadow
               receiveShadow
               geometry={nodes.Sphere_2.geometry}
               material={materials.Ground}
             />
             <mesh
               castShadow
               receiveShadow
               geometry={nodes.Sphere_3.geometry}
               material={materials["spike base"]}
             />
           </group>
         </mesh>
       </group>
     );
  }
//#endregion

//#region ClassicSansvieraial
export function ClassicSansvierial() {
  const { nodes, materials } = useGLTF(CLASSIC_SANSEVIERIA.href) as GLTFResult1;
  return (
    <group  dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.sansevieriapot001.geometry}
        material={materials["Material.005"]}
        position={[4.67, 0, -4.43]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.sansevieriapot002.geometry}
        material={materials["Material.005"]}
        position={[-4.66, 0, -4.43]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.sansevieriaplant001.geometry}
        material={materials["Material.013"]}
        position={[4.67, 0.92, -4.43]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.sansevieriaplant002.geometry}
        material={materials["Material.013"]}
        position={[-4.66, 0.92, -4.43]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.sansevieriagravel001.geometry}
        material={materials["Material.009"]}
        position={[4.67, 0.65, -4.43]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.sansevieriagravel002.geometry}
        material={materials["Material.009"]}
        position={[-4.66, 0.65, -4.43]}
      />
    </group>
  );
}
//#endregion

//#region ClassicPlnae
function ClassicPlane() {
  const { nodes, materials } = useGLTF(CLASSIC_PLANE.href) as GLTFResult1;
   return (
     <group dispose={null}>
       <mesh
         geometry={nodes.p.geometry}
         material={materials["plan.002"]}
         scale={[1, 0, 1]}
       />
     </group>
   );
}
//#endregion

//#region  ClassicPicture
function ClassicPicture() {
  const { nodes, materials } = useGLTF(CLASSIC_PICTURES.href) as GLTFResult1;
  return (
    <group dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.큐브013.geometry}
        material={materials.Picframe}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.큐브013_1.geometry}
        material={materials["pic1.008"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.큐브005.geometry}
        material={materials.Picframe}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.큐브005_1.geometry}
        material={materials["pic1.003"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.큐브002.geometry}
        material={materials.Picframe}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.큐브002_1.geometry}
        material={materials.picM}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.큐브009.geometry}
        material={materials.Picframe}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.큐브009_1.geometry}
        material={materials["pic1.002"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.큐브007.geometry}
        material={materials.Picframe}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.큐브007_1.geometry}
        material={materials["pic1.001"]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pickmainframe.geometry}
        material={materials.Material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pictureL.geometry}
        material={materials.Material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pictureL_1.geometry}
        material={materials.Material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pictureR.geometry}
        material={materials.Material}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pictureR_1.geometry}
        material={materials.Material}
      />
    </group>
  );
}
//#endregion

export function Classic(props: JSX.IntrinsicElements["group"]) {
  return (
    <>
      <ClassicSansvierial/>
      <ClassicPicture />
      <ClassicPlane />
      <ClassicCactus />
      <ClassicTable />
      <ClassicFrontPillar />
      <ClassicFrontWall />
      <ClassicLeftWall />
      <ClassicPlaneSide />
      <ClassicTopWall />
      <ClassicRightWall />
      <ClassicTopFrame />
      <ClassicWindow />
      <CompressedSofa/>
    </>
  );
}









