import * as THREE from "three";
import { OrbitControls, useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useLoader } from "@react-three/fiber";
import { TextureLoader} from "three";
import { useCompressedGLTF } from "./hooks";
import { useEffect, useRef } from "react";

type GLTFResult = GLTF & {
  nodes: {
    [key: string]: THREE.Mesh;
  };
  materials: {
    [key: string]: THREE.MeshStandardMaterial | THREE.MeshPhysicalMaterial;
  };
};



//#region DefaultModel URL

const default_Pictures = new URL("../static/DefaultTheme/default_Pictures.gltf", import.meta.url);
const default_NurbsPath = new URL("../static/DefaultTheme/default_NurbsPath.gltf", import.meta.url);
const DEFAULT_WALLLIGHT = new URL("../static/DefaultTheme/default_WallLight.gltf", import.meta.url);
const DEFAULT_ROOFFRAME = new URL("../static/DefaultTheme/default_RoofFrame.gltf", import.meta.url);
const DEFAULT_WALLS = new URL("../static/DefaultTheme/default_Walls.gltf", import.meta.url);
const DEFAULT_ROOF_LIGHTS = new URL("../static/DefaultTheme/default_Lights.gltf", import.meta.url);
const DEFAULT_PICTURES_LIGHTS = new URL("../static/DefaultTheme/default_Lights2.gltf", import.meta.url);
const DEFAULT_MAIN_PICTURE = new URL("../static/DefaultTheme/default_Main_Picture.gltf", import.meta.url);
const DEFAULT_CARPET = new URL("../static/DefaultTheme/default_Carpet.gltf", import.meta.url);
const DEFAULT_LAMP = new URL("../static/DefaultTheme/default_Lamp.gltf", import.meta.url);
const DEFAULT_TABLE = new URL("../static/DefaultTheme/default_Table.gltf", import.meta.url);
const DEFAULT_SOFA2 = new URL(
  "../static/DefaultTheme/model-sofa4.min.glb",
  import.meta.url
);

//#endregion


//#region  CompressedSofa
export function CompressedSofa() {
  const { nodes, materials } = useCompressedGLTF(DEFAULT_SOFA2.href) as GLTFResult;
  const modelRef = useRef() as any;
    useEffect(() => {
      // 모델이 로드된 후 위치 조정
      if (modelRef.current) {
        const box = new THREE.Box3().setFromObject(modelRef.current);
        const center = box.getCenter(new THREE.Vector3());
        modelRef.current.position.sub(center);

        modelRef.current.position.y = 0.01;
        modelRef.current.position.x = -2.1;
        modelRef.current.position.z = -1.9;
        
      }
    }, [nodes]);
  return (
    <>
      <group dispose={null} ref={modelRef}>
        <mesh
          ref ={modelRef}
          castShadow
          geometry={nodes.mesh_0.geometry}
          material={materials.Lime_sofa_V002}
          scale={0.00025}
        />
      </group>
    </>
  );
}
//#endregion

//#region DefaultTable
function DefaultTable() { 
  const { nodes, materials } = useGLTF(DEFAULT_TABLE.href) as GLTFResult;
  return (
    <group  dispose={null}>
      <group position={[0.1, 0.01, 0.06]} rotation={[0, -Math.PI / 4, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube006.geometry}
          material={materials.Clear_glass_table_V002}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube006_1.geometry}
          material={materials.Glass_002}
        />
      </group>
    </group>
  );
}

//#endregion

//#region DefaultLamp
function DefaultLamp() {
     const { nodes, materials } = useGLTF(DEFAULT_LAMP.href) as GLTFResult;
     return (
       <group dispose={null}>
         <group position={[-1.51, 0.01, 1.11]} rotation={[0, 0.12, 0]}>
           <mesh
             castShadow
             receiveShadow
             geometry={nodes.Cylinder015.geometry}
             material={materials["Material.011"]}
           />
           <mesh
             castShadow
             receiveShadow
             geometry={nodes.Cylinder015_1.geometry}
             material={materials["Material.009"]}
           />
           <mesh
             castShadow
             receiveShadow
             geometry={nodes.Cylinder015_2.geometry}
             material={materials["Material.007"]}
           />
         </group>
       </group>
     );
 }
//#endregion

//#region DefaultCarpet
function DefaultCarpet() { 
  const { nodes, materials } = useGLTF(DEFAULT_CARPET.href) as GLTFResult;
   materials["Material.006"].normalMap = null;
  return (
    <group  dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.carpet3.geometry}
        material={materials["Material.006"]}
        position={[0, -0.01, 0]}
        scale={1.88}
      />
    </group>
  );
}
//#endregion

//#region  DefaultPictureLights
function DefaultPictureLights() {
   const { nodes, materials } = useGLTF(DEFAULT_PICTURES_LIGHTS.href) as GLTFResult;
  return (
    <group dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.WallLight.geometry}
        material={materials["Metal.003"]}
        position={[-5, 5.76, -2.15]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder002.geometry}
        material={materials["Metal.003"]}
        position={[-5, 5.76, 2.73]}
      />
      <group position={[5, 5.76, 2.5]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder004_1.geometry}
          material={materials["Metal.003"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder004_2.geometry}
          material={materials["LED-Lighitng.002"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder004.geometry}
        material={materials["Metal.003"]}
        position={[5, 5.76, -2.38]}
      />
    </group>
  );
  
}
//#endregion

//#region  DefaultRoofLights
function DefaultRoofLights() {
    const { nodes, materials } = useGLTF(DEFAULT_ROOF_LIGHTS.href) as GLTFResult;
  return (
    <group dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder.geometry}
        material={materials["Material.002"]}
        position={[0, 10, -4.5]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder001.geometry}
        material={materials["Material.003"]}
        position={[0, 10, -4.5]}
      />
    </group>
  );
}
//#endregion

//#region DefaultWalss
function DefaultWalls() { 
   const { nodes, materials } = useGLTF(DEFAULT_WALLS.href) as GLTFResult;
  return (
    <group dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.T.geometry}
        material={nodes.T.material}
        position={[0, 10, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.L001.geometry}
        material={materials["Material.016"]}
        position={[-5, -1.53, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.L002.geometry}
        material={materials["Material.016"]}
        position={[-5, -11.37, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane002.geometry}
        material={materials["Material.017"]}
        position={[0, 0, 3.38]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[5, 1, 5]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003.geometry}
        material={materials["Material.017"]}
        position={[1.29, 0, 5.05]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[4.78, 0.97, 4.85]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane004.geometry}
        material={materials["Material.005"]}
        position={[1.29, 0, 4.73]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[4.78, 0.97, 4.85]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane001.geometry}
        material={materials["Material.017"]}
        position={[0.65, 1.46, 5.05]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[5.02, 1.02, 5.1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane005.geometry}
        material={materials["Material.017"]}
        position={[-1.28, 0, 5.05]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[-4.78, -0.97, -4.85]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane006.geometry}
        material={materials["Material.005"]}
        position={[-1.28, 0, 4.73]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[-4.78, -0.97, -4.85]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane007.geometry}
        material={materials["Material.017"]}
        position={[-0.64, 1.46, 5.05]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[5.02, 1.02, 5.1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.R.geometry}
        material={materials["Material.004"]}
        position={[5, 0, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.B.geometry}
        material={materials["Material.004"]}
        position={[0, 0, -5]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.L.geometry}
        material={materials["Material.004"]}
        position={[-5, 0, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane.geometry}
        material={materials["Material.004"]}
        position={[0, 0, 5]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[5, 1, 5]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.PH.geometry}
        material={materials["plan.002"]}
      />
    </group>
  )
}
//#endregion

//#region  images URL
const default_main_image = new URL("../static/default/defaultmain.jpg", import.meta.url);
const default_right_first_image = new URL(
  "../static/default/100330ab.jpg",
  import.meta.url
);
const default_right_second_image = new URL(
  "../static/default/101816ab.jpg",
  import.meta.url
);
const default_left_first_image = new URL(
  "../static/default/105071ab.jpg",
  import.meta.url
);
const default_left_second_image = new URL(
  "../static/default/107165ab.jpg",
  import.meta.url
);
//#endregion

//#region  RoofFrame
function RoofFrame() {
  const { nodes, materials } = useGLTF(DEFAULT_ROOFFRAME.href) as GLTFResult;
  return (
    <group dispose={null}>
      <group position={[0, 10, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001.geometry}
          material={materials["Material.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001_1.geometry}
          material={materials.frame}
        />
      </group>
    </group>
  );
}
//#endregion

//#region  NurbsPath
function NurbsPath() {
    const { nodes, materials } = useGLTF(default_NurbsPath.href) as GLTFResult;
    return (
      <group  dispose={null}>
        <group position={[0.04, 0, 0.08]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.NurbsPath_1.geometry}
            material={materials["Material.019"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.NurbsPath_2.geometry}
            material={materials["Material.018"]}
          />
        </group>
      </group>
    );
}
//#endregion

//#region  WallLight
function WallLight() { 
  const { nodes, materials } = useGLTF(DEFAULT_WALLLIGHT.href) as GLTFResult;
  return (
    <group dispose={null}>
      <group position={[-0.37, 2.09, 0.16]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Starlux_wall_light_1.geometry}
          material={materials["Simple golden metal"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Starlux_wall_light_2.geometry}
          material={materials["milky glass"]}
        />
      </group>
      <group position={[2.98, 2.09, 0.16]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Starlux_wall_light001_1.geometry}
          material={materials["Simple golden metal"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Starlux_wall_light001_2.geometry}
          material={materials["milky glass"]}
        />
      </group>
    </group>
  );
}
//#endregion

//#region  DefautlPicture
function DefaultPictures(){
  const { nodes, materials } = useGLTF(default_Pictures.href) as GLTFResult;
  const { nodes: nodes2, materials: materials2 } = useGLTF(DEFAULT_MAIN_PICTURE.href) as GLTFResult;
  const right_image = useLoader(TextureLoader, default_right_first_image.href);
  const right_image2 = useLoader(
    TextureLoader,
    default_right_second_image.href
  );
  const left_image = useLoader(TextureLoader, default_left_first_image.href);
  const left_image2 = useLoader(TextureLoader, default_left_second_image.href);
  materials["Material.014"].map = left_image2; //left second
  materials["Material.013"].map = left_image; // left first
  materials["Material.010"].map = right_image; //right first
  materials["Material.012"].map = right_image2; //right second

  const main_image = useLoader(TextureLoader, default_main_image.href);
  materials2["Material.015"].map = main_image; // main image
  return (
    <group dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes2["pictureMain_001"].geometry}
        material={materials2.Material}
        position={[0, 0.9, -5]}
        scale={[1.13, 1, 1]}
      />
      <group position={[0, 1, -4.98]} scale={[1.13, 1, 1]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes2.큐브052.geometry}
          material={materials2.Picframe}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes2.큐브052_1.geometry}
          material={materials2["Material.015"]}
        />
      </group>

      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pictureRF_005.geometry}
        material={materials.Material}
        position={[-5.01, 0.9, 2.7]}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pictureRF_005001.geometry}
        material={materials.Material}
        position={[-5.01, 0.9, -2.19]}
        rotation={[0, -1.57, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pictureRF_005003.geometry}
        material={materials.Material}
        position={[5.01, 0.9, 2.2]}
        rotation={[0, 1.57, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pictureRF_005002.geometry}
        material={materials.Material}
        position={[5.01, 0.9, -2.7]}
        rotation={[0, 1.57, 0]}
      />
      <group position={[-4.98, 1, -2.19]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.큐브014.geometry}
          material={materials.Picframe}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.큐브014_1.geometry}
          material={materials["Material.013"]}
        />
      </group>
      <group position={[-4.98, 1, 2.7]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.큐브013.geometry}
          material={materials.Picframe}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.큐브013_1.geometry}
          material={materials["Material.014"]}
        />
      </group>
      <group position={[4.98, 1, 2.2]} rotation={[0, Math.PI / 2, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.큐브003.geometry}
          material={materials.Picframe}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.큐브003_1.geometry}
          material={materials["Material.012"]}
        />
      </group>
      <group position={[4.98, 1, -2.7]} rotation={[0, 1.57, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.큐브019.geometry}
          material={materials.Picframe}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.큐브019_1.geometry}
          material={materials["Material.010"]}
        />
      </group>
    </group>
  );
}
//#endregion

export function Default(props: JSX.IntrinsicElements["group"]) {
  return (
    <>
      <DefaultLamp/>
      <DefaultRoofLights/>
      <RoofFrame/>
      <NurbsPath/>
      <DefaultPictures />
      <WallLight />
      <DefaultWalls />
      <DefaultPictureLights />
      <DefaultCarpet />
      <DefaultTable />
      <CompressedSofa/>
    </>
  );
}
