export default class bgmOnOff {
  states: boolean;
  xButton: boolean;
  constructor() {
    this.states = false;
    this.xButton = true;
  }

  OnOff() {
      if (this.xButton === true) {
          if (this.states === false) {
            this.states = true;
          }
          else if (this.states === true) { 
            this.states = false;
          }
        this.xButton = false;
      return this.states
    } else {
        return this.states;
    }
  }

}
